import { ChangeEvent, useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import {
  Stack,
  Typography,
  Button,
  useTheme,
  LinearProgress,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import GenericRetryError from "@ob/components/GenericRetryError";
import LocalErrorMsg from "@ob/components/LocalErrorMsg";
import { FadeInStack } from "@ob/components/FadeComponents";
import {
  selectBeneficialOwners,
  selectActiveIndex,
  selectBeneficialOwnerByParam,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/owner";
import {
  selectBusinessError,
  selectBusinessFetching,
  selectBusinessSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/sync";
import formatBirthday from "@ob/utils/formatBirthday";
import {
  validateFirstName,
  validateLastName,
  validateBirthday,
  validateEmail,
  validateSSN,
} from "@ob/utils/validation";
import { validateTitle } from "@ob/layouts/VendorOnboarding/utils/validation";
import formatSSN from "@ob/utils/formatSSN";

export default function BeneficialOwnerInfo() {
  const params = useParams<{ index: string }>();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState(false);
  const [uiErrors, setUiErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    ssn: "",
    dob: "",
  });

  const hasErrors = [
    uiErrors.firstName,
    uiErrors.lastName,
    uiErrors.email,
    uiErrors.title,
    uiErrors.ssn,
    uiErrors.dob,
  ].some(Boolean);

  const activeIndex = useAppSelector(selectActiveIndex);
  const allOwners = useAppSelector(selectBeneficialOwners);
  let owner = allOwners[activeIndex];
  const paramValue = params.index ? Number(params.index) : undefined;
  if (paramValue !== undefined) {
    owner = useAppSelector((state) =>
      selectBeneficialOwnerByParam(state, paramValue),
    );
  }
  const apiFetching = useAppSelector(selectBusinessFetching);
  const apiError = useAppSelector(selectBusinessError);
  const apiSuccess = useAppSelector(selectBusinessSuccess);

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      setUiErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      if (!hasErrors) {
        setShowErrors(false);
      }
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleValidation = (name: string, value: string) => {
    switch (name) {
      case "firstName":
        return validateFirstName(value, handleValidationResult(name));
      case "lastName":
        return validateLastName(value, handleValidationResult(name));
      case "email":
        return validateEmail(value, handleValidationResult(name));
      case "ssn":
        return validateSSN(value, handleValidationResult(name));
      case "dob":
        return validateBirthday(value, handleValidationResult(name));
      case "title":
        return validateTitle(value, handleValidationResult(name));
      default:
        break;
    }
    return false;
  };

  const handleSubmit = () => {
    const validFirstname = validateFirstName(
      owner.info.firstName,
      handleValidationResult("firstName"),
    );
    const validLastname = validateLastName(
      owner.info.lastName,
      handleValidationResult("lastName"),
    );
    const validEmail = validateEmail(
      owner.info.email,
      handleValidationResult("email"),
    );
    const validSSN = validateSSN(owner.info.ssn, handleValidationResult("ssn"));
    const validDOB = validateBirthday(
      formatBirthday(owner.info.dob),
      handleValidationResult("dob"),
    );
    if (
      !validFirstname ||
      !validLastname ||
      !validEmail ||
      !validSSN ||
      !validDOB
    ) {
      setShowErrors(true);
      return;
    }
    if (!apiFetching) {
      dispatch(va.kyb.sync.updateBusiness());
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    handleValidation(name, value);
    dispatch(va.kyb.owners.setInfoValue(name, value));
  };

  const handleOnDobKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const value = e.currentTarget.value.replace(/\s+/g, "");
    if (key === "Backspace") {
      let nextValue = value.slice(0, -1);
      if (value.slice(-1) === "/") {
        nextValue = value.slice(0, -2);
      }
      dispatch(va.kyb.owners.setInfoValue("dob", nextValue));
    }
  };

  const handleOnDobChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s+/g, "");
    if (
      "inputType" in e.nativeEvent &&
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      return;
    }

    if (!value || /^\D+$/.test(value)) {
      const nextValue = value.replace(/\D/g, "");
      dispatch(va.kyb.owners.setInfoValue("dob", nextValue));
    } else if (value.length <= 10) {
      dispatch(va.kyb.owners.setInfoValue("dob", value));
    }
  };

  const handleOnSSNKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;
    const { key } = e;
    const value = e.currentTarget.value.replace(/\s+/g, "");
    if (key === "Backspace") {
      let nextValue = value.slice(0, -1);
      if (value.slice(-1) === "-") {
        nextValue = value.slice(0, -2);
      }
      validateSSN(nextValue, handleValidationResult(name));
      dispatch(va.kyb.owners.setInfoValue("ssn", nextValue));
    }
    const allowSubmit = [
      e.key === "Enter",
      !apiFetching,
      !uiErrors.ssn,
      value.length === 11,
    ].every(Boolean);
    if (allowSubmit) {
      handleSubmit();
    }
  };

  const handleOnSSNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (
      "inputType" in e.nativeEvent &&
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      return;
    }
    validateSSN(value, handleValidationResult(name));
    if (!value || /^\D+$/.test(value)) {
      const nextValue = value.replace(/\D/g, "");
      dispatch(va.kyb.owners.setInfoValue("ssn", nextValue));
    } else if (value.length <= 11) {
      dispatch(va.kyb.owners.setInfoValue("ssn", value));
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;
    let { value } = e.currentTarget;
    if (name === "dob") {
      value = formatBirthday(e.target.value.replace(/\s+/g, ""));
    } else if (name === "ssn") {
      value = formatSSN(value);
    }
    const valid = handleValidation(name, value);
    if (!valid) {
      setShowErrors(true);
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowSubmit = [
      e.key === "Enter",
      !apiFetching,
      !uiErrors.firstName,
      !uiErrors.lastName,
      !uiErrors.email,
      !uiErrors.ssn,
      !uiErrors.dob,
      owner.info.firstName,
      owner.info.lastName,
      owner.info.email,
      owner.info.ssn,
      owner.info.dob,
    ].every(Boolean);
    if (allowSubmit) {
      handleSubmit();
    }
  };

  const clearAsyncState = useCallback(() => {
    dispatch(va.kyb.sync.apiSuccess(false));
    dispatch(va.kyb.sync.apiFetching(false));
    dispatch(
      va.kyb.sync.apiError({
        status: 0,
        message: "",
      }),
    );
  }, []);

  const handleRemoveBeneficialOwner = (index: number | undefined) => {
    if (index === undefined) {
      return;
    }
    dispatch(va.kyb.owners.removeBeneficialOwner(index));
    clearAsyncState();
    navigate("/kyb/review");
  };

  useEffect(() => {
    if (apiSuccess) {
      clearAsyncState();
      if (paramValue !== undefined) {
        navigate(`/kyb/owners_address/${paramValue}`);
      } else {
        navigate("/kyb/owners_address");
      }
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (!hasErrors && showErrors) {
      setShowErrors(false);
    }
  }, [hasErrors]);

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAsyncState();
    if (paramValue !== undefined) {
      dispatch(va.kyb.owners.setActiveIndex(paramValue));
    }
    return () => clearAsyncState();
  }, []);

  return (
    <FadeInStack
      gap={2}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100vw",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start" gap={1}>
        <LinearProgress
          variant="determinate"
          value={92}
          sx={{
            width: "100%",
          }}
        />
        <Typography variant="h2">Beneficial owner</Typography>
        <Typography variant="body1">
          Provide the information for a representative that owns &ge;25% of the
          business.
        </Typography>
      </Stack>
      <Stack
        data-testid="owner-info-input"
        gap={2}
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          whiteSpace: "nowrap",
          height: "calc(100vh - 430px)",
          "& .MuiAutocomplete-popper": {
            "::-webkit-scrollbar": {
              width: "12px",
              height: "7px",
            },
            "::-webkit-scrollbar-track": {
              background: "#F1F1F1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#ddd",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#333",
            },
          },
        }}
      >
        <Stack gap={1}>
          <Typography variant="body1">Name</Typography>
          <Stack gap={1}>
            <input
              disabled={apiFetching}
              type="string"
              inputMode="text"
              placeholder="First name"
              name="firstName"
              value={owner.info.firstName}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onKeyDown={handleOnKeyDown}
              className="firstName-input"
              style={{
                color: theme.palette.primary.main,
                border: `1px solid ${
                  uiErrors.firstName && showErrors
                    ? theme.palette.error.main
                    : theme.palette.secondary.light
                }`,
                backgroundColor: "transparent",
                borderRadius: "4px",
                height: "40px",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "CircularRegular",
                paddingLeft: "10px",
                width: "100%",
              }}
            />
            <Typography
              variant="subtitle1"
              color="error"
              sx={{
                pl: "10px",
                display: showErrors && uiErrors.firstName ? "block" : "none",
                lineHeight: "16px",
              }}
            >
              {uiErrors.firstName}
            </Typography>
          </Stack>
          <Stack gap={1}>
            <input
              disabled={apiFetching}
              type="string"
              inputMode="text"
              placeholder="Last name"
              name="lastName"
              value={owner.info.lastName}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onKeyDown={handleOnKeyDown}
              className="lastName-input"
              style={{
                color: theme.palette.primary.main,
                border: `1px solid ${
                  uiErrors.lastName && showErrors
                    ? theme.palette.error.main
                    : theme.palette.secondary.light
                }`,
                backgroundColor: "transparent",
                borderRadius: "4px",
                height: "40px",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "CircularRegular",
                paddingLeft: "10px",
                width: "100%",
              }}
            />
            <Typography
              variant="subtitle1"
              color="error"
              sx={{
                pl: "10px",
                display: showErrors && uiErrors.lastName ? "block" : "none",
                lineHeight: "16px",
              }}
            >
              {uiErrors.lastName}
            </Typography>
          </Stack>
        </Stack>
        <Stack gap={1}>
          <Typography variant="body1">Email</Typography>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="Business owner's email"
            name="email"
            value={owner.info.email}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
            className="email-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.firstName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pl: "10px",
              display: showErrors && uiErrors.email ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.email}
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="body1">Title</Typography>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="Business owner's title"
            name="title"
            value={owner.info.title}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
            className="title-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.firstName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pl: "10px",
              display: showErrors && uiErrors.title ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.title}
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="body1">SSN</Typography>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="###-##-####"
            name="ssn"
            value={formatSSN(owner.info.ssn)}
            onChange={handleOnSSNChange}
            onBlur={handleOnBlur}
            onKeyDown={handleOnSSNKeyDown}
            className="ssn-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.firstName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pl: "10px",
              display: showErrors && uiErrors.ssn ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.ssn}
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="body1">DOB</Typography>
          <input
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="MM / DD / YYYY"
            name="dob"
            value={formatBirthday(owner.info.dob)}
            onChange={handleOnDobChange}
            onKeyDown={handleOnDobKeyDown}
            onBlur={handleOnBlur}
            className="birthday-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.firstName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pl: "10px",
              display: showErrors && uiErrors.dob ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.dob}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={3} sx={{ height: "100px" }}>
        <Stack gap={1}>
          <GenericRetryError error={apiError} onClick={handleSubmit} />
          <LocalErrorMsg error={apiError} />
        </Stack>
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{ color: theme.palette.secondary.main }}
        >
          We are required by law to collect this information to authorize
          payments.
        </Typography>
        <Stack gap={1}>
          <StitchAsyncButton
            buttonText="Continue"
            variant="contained"
            color="primary"
            logoColor="white"
            onClick={handleSubmit}
            success={apiSuccess}
            loading={apiFetching}
            loadingSize="small"
            loadingPosition={{ top: -31, left: 0 }}
          />
          {paramValue !== undefined && (
            <Button
              variant="outlined"
              onClick={() => handleRemoveBeneficialOwner(paramValue)}
              sx={{ borderColor: theme.palette.secondary.light }}
            >
              Remove
            </Button>
          )}
        </Stack>
      </Stack>
    </FadeInStack>
  );
}
