import { SingleBeneficialOwnerStateType } from "@ob/layouts/VendorOnboarding/types/kyb/owners";

export function validateTitle(
  value: string,
  cb: (error: string) => void,
): boolean {
  // NOTE: Allow any alphanumeric, unicode basic Latin characters, unicode Latin extended-A,
  // ampersands (&), apostrophes ('), dashes (-), periods (.), commas (,), and spaces.
  // See https://en.wikipedia.org/wiki/List_of_Unicode_characters#Latin_script
  const characterSet = "a-zA-Z0-9À-ÖØ-öø-ÿĀ-ſ";
  const nameRegex = new RegExp(`^[${characterSet}][${characterSet}' ,. -&]+$`);
  if (!value) {
    cb("Title is required");
  } else if (value && !nameRegex.test(value)) {
    cb("Invalid Title");
  } else {
    cb("");
    return true;
  }
  return false;
}

export type CheckBusinessInfoReqType = {
  isValid: boolean;
  errorMsg: string;
};
export function checkBusinessBeneficialOwnersForSubmit(
  beneficialOwners: SingleBeneficialOwnerStateType[],
): CheckBusinessInfoReqType {
  if (beneficialOwners.length === 0) {
    return {
      isValid: false,
      errorMsg: "Business must have beneficial owners",
    };
  }
  if (beneficialOwners.length > 4) {
    return {
      isValid: false,
      errorMsg: "Business may not have more than four beneficial owners",
    };
  }

  return { isValid: true, errorMsg: "" };
}
