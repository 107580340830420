import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import {
  Stack,
  Typography,
  useTheme,
  Button,
  LinearProgress,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import LocalErrorMsg from "@ob/components/LocalErrorMsg";
import GenericRetryError from "@ob/components/GenericRetryError";
import { FadeInStack } from "@ob/components/FadeComponents";
import {
  selectBeneficialOwners,
  selectActiveIndex,
  selectBeneficialOwnerByParam,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/owner";
import {
  selectBusinessError,
  selectBusinessFetching,
  selectBusinessSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/sync";
import {
  validateStreet1,
  validateStreet2,
  validateCity,
  validateState,
  validateZip,
} from "@ob/utils/validation";
import USStateSelect from "@ob/components/USStateSelect";

export default function BeneficialOwnerAddress() {
  const params = useParams<{ index: string }>();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showErrors, setShowErrors] = useState(false);
  const [uiErrors, setUiErrors] = useState({
    street1: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
  });
  const hasErrors =
    uiErrors.street1 ||
    uiErrors.street2 ||
    uiErrors.city ||
    uiErrors.state ||
    uiErrors.zip;

  const activeIndex = useAppSelector(selectActiveIndex);
  const allOwners = useAppSelector(selectBeneficialOwners);
  let owner = allOwners[activeIndex];
  const paramValue = params.index ? Number(params.index) : undefined;
  if (paramValue !== undefined) {
    owner = useAppSelector((state) =>
      selectBeneficialOwnerByParam(state, paramValue),
    );
  }
  const apiError = useAppSelector(selectBusinessError);
  const apiFetching = useAppSelector(selectBusinessFetching);
  const apiSuccess = useAppSelector(selectBusinessSuccess);

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      setUiErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      if (!hasErrors) {
        setShowErrors(false);
      }
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = () => {
    const validAddress = [
      validateStreet1(owner.address.street1, handleValidationResult("street1")),
      validateStreet2(owner.address.street2, handleValidationResult("street2")),
      validateCity(owner.address.city, handleValidationResult("city")),
      validateState(owner.address.state, handleValidationResult("state")),
      validateZip(owner.address.zip, handleValidationResult("zip")),
    ].every((error) => error);
    if (!validAddress) {
      setShowErrors(true);
      return;
    }
    if (!apiFetching) {
      dispatch(va.kyb.sync.updateBusiness());
    }
  };

  const handleValidation = (name: string, value: string): boolean => {
    switch (name) {
      case "street1":
        return validateStreet1(value, handleValidationResult(name));
      case "street2":
        return validateStreet2(value, handleValidationResult(name));
      case "city":
        return validateCity(value, handleValidationResult(name));
      case "state":
        return validateState(value, handleValidationResult(name));
      case "zip":
        return validateZip(value, handleValidationResult(name));
      default:
        break;
    }
    return false;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleValidation(name, value);
    dispatch(va.kyb.owners.setAddressValue(name, value));
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const valid = handleValidation(name, value);
    if (!valid) {
      setShowErrors(true);
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowSubmit = [
      !uiErrors.street1,
      !uiErrors.city,
      !uiErrors.state,
      !uiErrors.zip,
      owner.address.street1,
      owner.address.city,
      owner.address.state,
      owner.address.zip,
      !apiFetching,
      e.key === "Enter",
    ].every(Boolean);
    if (allowSubmit) {
      handleSubmit();
    }
  };

  const clearAsyncState = useCallback(() => {
    dispatch(va.kyb.sync.apiSuccess(false));
    dispatch(va.kyb.sync.apiFetching(false));
    dispatch(
      va.kyb.sync.apiError({
        status: 0,
        message: "",
      }),
    );
  }, []);

  const handleRemoveBeneficialOwner = (index: number | undefined) => {
    if (index === undefined) {
      return;
    }
    dispatch(va.kyb.owners.removeBeneficialOwner(index));
    clearAsyncState();
    navigate("/kyb/review");
  };

  useEffect(() => {
    if (!hasErrors && showErrors) {
      setShowErrors(false);
    }
  }, [hasErrors]);

  useEffect(() => {
    if (apiSuccess) {
      clearAsyncState();
      navigate("/kyb/review");
    }
  }, [apiSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAsyncState();
    return () => clearAsyncState();
  }, []);

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100vw",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start" gap={1}>
        <LinearProgress
          variant="determinate"
          value={96}
          sx={{
            width: "100%",
          }}
        />
        <Typography variant="h2">Beneficial owner</Typography>
        <Typography variant="body1">
          Provide the information for a representative that owns &ge;25% of the
          business.
        </Typography>
      </Stack>
      <Stack data-testid="address" gap={2}>
        <Stack gap={1}>
          <Typography variant="body1">Address</Typography>
          <Stack>
            <input
              disabled={apiFetching}
              type="string"
              inputMode="text"
              placeholder="Street 1"
              name="street1"
              value={owner.address.street1}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onKeyDown={handleOnKeyDown}
              onInput={() => setUiErrors((prev) => ({ ...prev, street1: "" }))}
              className="address-input"
              style={{
                color: theme.palette.primary.main,
                border: `1px solid ${
                  uiErrors.street1 && showErrors
                    ? theme.palette.error.main
                    : theme.palette.secondary.light
                }`,
                backgroundColor: "transparent",
                borderRadius: "4px",
                height: "40px",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "CircularRegular",
                paddingLeft: "10px",
                width: "100%",
              }}
            />
            <Typography
              variant="subtitle1"
              color="error"
              sx={{
                pt: "5px",
                pl: "10px",
                display: showErrors && uiErrors.street1 ? "block" : "none",
                lineHeight: "16px",
              }}
            >
              {uiErrors.street1}
            </Typography>
          </Stack>
          <Stack>
            <input
              disabled={apiFetching}
              type="string"
              inputMode="text"
              placeholder="Street 2"
              name="street2"
              value={owner.address.street2}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onKeyDown={handleOnKeyDown}
              className="address-input"
              style={{
                color: theme.palette.primary.main,
                border: `1px solid ${
                  uiErrors.street2 && showErrors
                    ? theme.palette.error.main
                    : theme.palette.secondary.light
                }`,
                backgroundColor: "transparent",
                borderRadius: "4px",
                height: "40px",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "CircularRegular",
                paddingLeft: "10px",
                width: "100%",
              }}
            />
            <Typography
              variant="subtitle1"
              color="error"
              sx={{
                pt: "5px",
                pl: "10px",
                display: showErrors && uiErrors.street2 ? "block" : "none",
                lineHeight: "16px",
              }}
            >
              {uiErrors.street2}
            </Typography>
          </Stack>
          <Stack>
            <input
              disabled={apiFetching}
              type="string"
              inputMode="text"
              placeholder="City"
              name="city"
              value={owner.address.city}
              autoCorrect={"off"} // disabled to prevent state autocomplete from crashing
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onInput={() => setUiErrors((prev) => ({ ...prev, city: "" }))}
              onKeyDown={handleOnKeyDown}
              className="address-input"
              style={{
                color: theme.palette.primary.main,
                border: `1px solid ${
                  uiErrors.city && showErrors
                    ? theme.palette.error.main
                    : theme.palette.secondary.light
                }`,
                backgroundColor: "transparent",
                borderRadius: "4px",
                height: "40px",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "CircularRegular",
                paddingLeft: "10px",
                width: "100%",
              }}
            />
            <Typography
              variant="subtitle1"
              color="error"
              sx={{
                pt: "5px",
                pl: "10px",
                display: showErrors && uiErrors.city ? "block" : "none",
                lineHeight: "16px",
              }}
            >
              {uiErrors.city}
            </Typography>
          </Stack>
          <Stack>
            <USStateSelect
              onChange={handleOnChange}
              initialStateCode={owner.address.state}
            />
            <Typography
              variant="subtitle1"
              color="error"
              sx={{
                pt: "5px",
                pl: "10px",
                display: showErrors && uiErrors.state ? "block" : "none",
                lineHeight: "16px",
              }}
            >
              {uiErrors.state}
            </Typography>
          </Stack>
          <Stack>
            <input
              disabled={apiFetching}
              type="string"
              inputMode="text"
              placeholder="Zip code"
              name="zip"
              value={owner.address.zip}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onInput={() => setUiErrors((prev) => ({ ...prev, zip: "" }))}
              onKeyDown={handleOnKeyDown}
              className="address-input"
              style={{
                color: theme.palette.primary.main,
                border: `1px solid ${
                  uiErrors.zip && showErrors
                    ? theme.palette.error.main
                    : theme.palette.secondary.light
                }`,
                backgroundColor: "transparent",
                borderRadius: "4px",
                height: "40px",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "CircularRegular",
                paddingLeft: "10px",
                width: "100%",
              }}
            />
            <Typography
              variant="subtitle1"
              color="error"
              sx={{
                pt: "5px",
                pl: "10px",
                display: showErrors && uiErrors.zip ? "block" : "none",
                lineHeight: "16px",
              }}
            >
              {uiErrors.zip}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack gap={4}>
        <Stack gap={1}>
          <GenericRetryError error={apiError} onClick={handleSubmit} />
          <LocalErrorMsg error={apiError} />
        </Stack>
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{ color: theme.palette.secondary.main }}
        >
          We are required by law to collect this information to authorize
          payments.
        </Typography>
        <Stack gap={1}>
          <StitchAsyncButton
            buttonText="Continue"
            variant="contained"
            color="primary"
            logoColor="white"
            onClick={handleSubmit}
            success={apiSuccess}
            loading={apiFetching}
            loadingSize="small"
            loadingPosition={{ top: -31, left: 0 }}
          />
          {paramValue !== undefined && (
            <Button
              variant="outlined"
              onClick={() => handleRemoveBeneficialOwner(paramValue)}
              sx={{ borderColor: theme.palette.secondary.light }}
            >
              Remove
            </Button>
          )}
        </Stack>
      </Stack>
    </FadeInStack>
  );
}
