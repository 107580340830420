import { LinearProgress, Typography } from "@mui/material";
import { Stack, useTheme } from "@mui/system";
import { FadeInStack } from "@ob/components/FadeComponents";
import GenericRetryError from "@ob/components/GenericRetryError";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import { selectTitle } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/controller/info";
import {
  selectBusinessError,
  selectBusinessFetching,
  selectBusinessSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/sync";
import {
  selectFirstName,
  selectLastName,
  selectEmail,
  selectDOB,
  selectSSN,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/admin/info";
import {
  selectStreet1,
  selectStreet2,
  selectCity,
  selectState,
  selectZip,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/admin/address";
import { validateTitle } from "@ob/layouts/VendorOnboarding/utils/validation";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { selectBusinessName } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/business/info";

export default function BusinessControllerTitle() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const apiFetching = useAppSelector(selectBusinessFetching);
  const apiError = useAppSelector(selectBusinessError);
  const apiSuccess = useAppSelector(selectBusinessSuccess);
  const title = useAppSelector(selectTitle);
  const businessName = useAppSelector(selectBusinessName);
  const adminFirstName = useAppSelector(selectFirstName);
  const adminLastName = useAppSelector(selectLastName);
  const adminEmail = useAppSelector(selectEmail);
  const adminDOB = useAppSelector(selectDOB);
  const adminSSN = useAppSelector(selectSSN);
  const adminStreet1 = useAppSelector(selectStreet1);
  const adminStreet2 = useAppSelector(selectStreet2);
  const adminCity = useAppSelector(selectCity);
  const adminState = useAppSelector(selectState);
  const adminZip = useAppSelector(selectZip);
  const [showErrors, setShowErrors] = useState(false);
  const [uiTitleError, setUiTitleError] = useState("");

  const handleValidationResult = () => (error: string) => {
    if (error) {
      setUiTitleError(error);
    } else {
      setShowErrors(false);
      setUiTitleError("");
    }
  };

  const handleValidation = (value: string): boolean =>
    validateTitle(value, handleValidationResult());

  const setControllerInfoFromAdmin = () => {
    // NOTE: On this page, the business controller is the same as the business admin.
    // The only information that needs to be set from user input on this page is the title.
    dispatch(va.kyb.controller.info.setFirstName(adminFirstName));
    dispatch(va.kyb.controller.info.setLastName(adminLastName));
    dispatch(va.kyb.controller.info.setEmail(adminEmail));
    dispatch(va.kyb.controller.info.setDOB(adminDOB));
    dispatch(va.kyb.controller.info.setSSN(adminSSN));
    dispatch(
      va.kyb.controller.address.setAddressValue("street1", adminStreet1),
    );
    dispatch(
      va.kyb.controller.address.setAddressValue("street2", adminStreet2),
    );
    dispatch(va.kyb.controller.address.setAddressValue("city", adminCity));
    dispatch(va.kyb.controller.address.setAddressValue("state", adminState));
    dispatch(va.kyb.controller.address.setAddressValue("zip", adminZip));
  };

  const handleOnTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    handleValidation(value);
    dispatch(va.kyb.controller.info.setTitle(value));
  };

  const handleOnTitleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const isValid = handleValidation(value);
    setShowErrors(!isValid);
  };

  const handleSubmit = () => {
    const validTitle = validateTitle(title, handleValidationResult());

    if (!validTitle) {
      setShowErrors(true);
      return;
    }

    if (!apiFetching) {
      setControllerInfoFromAdmin();
      dispatch(va.kyb.sync.updateBusiness());
    }
  };

  const handleOnTitleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowSubmit = [
      e.key === "Enter",
      !apiFetching,
      !uiTitleError,
      title,
    ].every(Boolean);
    if (allowSubmit) {
      handleSubmit();
    }
  };

  useEffect(() => {
    dispatch(va.kyb.sync.apiSuccess(false));
    dispatch(va.kyb.sync.apiFetching(false));
    dispatch(
      va.kyb.sync.apiError({
        status: 0,
        message: "",
      }),
    );
  }, []);

  useEffect(
    () =>
      function unmount() {
        dispatch(va.kyb.sync.apiSuccess(false));
        dispatch(va.kyb.sync.apiFetching(false));
        dispatch(
          va.kyb.sync.apiError({
            status: 0,
            message: "",
          }),
        );
      },
    [],
  );

  useEffect(() => {
    if (apiSuccess) {
      dispatch(va.kyb.sync.apiSuccess(false));
      dispatch(va.kyb.sync.apiFetching(false));
      dispatch(
        va.kyb.sync.apiError({
          status: 0,
          message: "",
        }),
      );
      navigate("/kyb/review");
    }
  }, [apiSuccess]);

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100vw",
        flexGrow: 1,
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start" gap={1}>
        <LinearProgress
          variant="determinate"
          value={84}
          sx={{
            width: "100%",
          }}
        />
        <Typography variant="h2">Your title</Typography>
        <Typography variant="body1">
          Enter your title with {businessName}.
        </Typography>
      </Stack>
      <Stack data-testid="controller-input" gap={1}>
        <Stack>
          <input
            id="title-input"
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="Title"
            name="title"
            value={title}
            onChange={handleOnTitleChange}
            onBlur={handleOnTitleBlur}
            onKeyDown={handleOnTitleKeyDown}
            className="title-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiTitleError && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiTitleError ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiTitleError}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={1}>
        <GenericRetryError error={apiError} onClick={handleSubmit} />
        <StitchAsyncButton
          buttonText="Continue"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={handleSubmit}
          loading={apiFetching}
          success={apiSuccess}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}
