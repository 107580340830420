import { ReactNode, useCallback, useEffect, useState } from "react";
import {
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FadeInStack } from "@ob/components/FadeComponents";
import GenericRetryError from "@ob/components/GenericRetryError";
import LocalErrorMsg from "@ob/components/LocalErrorMsg";
import StitchAsyncButton from "@ob/components/StitchAsyncButton";
import { selectBusinessInfo } from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/business/info";
import { useAppDispatch, useAppSelector } from "@ob/redux/store";
import va from "@ob/layouts/VendorOnboarding/redux/actions";
import {
  validateBusinessName,
  validateBusinessStructure,
  validateDoingBusinessAs,
  validateEin,
  validateRequiredEin,
} from "@ob/utils/validation";
import { useNavigate } from "react-router";
import { BusinessStructureType } from "@ob/layouts/VendorOnboarding/types/kyb/business/info";
import {
  selectBusinessError,
  selectBusinessFetching,
  selectBusinessSuccess,
} from "@ob/layouts/VendorOnboarding/redux/selectors/kyb/sync";
import formatEin from "@ob/utils/formatEin";

export default function BusinessInfo() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const apiFetching = useAppSelector(selectBusinessFetching);
  const apiError = useAppSelector(selectBusinessError);
  const apiSuccess = useAppSelector(selectBusinessSuccess);
  const info = useAppSelector(selectBusinessInfo);
  const [showErrors, setShowErrors] = useState(false);
  const [uiErrors, setUiErrors] = useState({
    businessName: "",
    businessEin: "",
    businessStructure: "",
    doingBusinessAs: "",
  });

  const handleValidationResult = (name: string) => (error: string) => {
    if (error) {
      setUiErrors({ ...uiErrors, [name]: error });
    } else {
      setShowErrors(false);
      setUiErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleValidation = (name: string, value: string): boolean => {
    switch (name) {
      case "businessName":
        return validateBusinessName(value, handleValidationResult(name));
      case "businessEin":
        return validateEin(value, handleValidationResult(name));
      case "doingBusinessAs":
        return validateDoingBusinessAs(value, handleValidationResult(name));
      default:
        return true;
    }
  };

  const handleOnBusinessNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.currentTarget;
    handleValidation("businessName", value);
    dispatch(va.kyb.business.info.setBusinessName(value));
  };

  const handleOnDoingBusinessAsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.currentTarget;
    handleValidation("doingBusinessAs", value);
    dispatch(va.kyb.business.info.setDoingBusinessAs(value));
  };

  const handleOnBusinessStructureChange = (
    e: SelectChangeEvent<BusinessStructureType | null>,
    _: ReactNode,
  ) => {
    const value = e.target.value as BusinessStructureType;
    const isValid = validateBusinessStructure(
      value,
      handleValidationResult("businessStructure"),
    );
    setShowErrors(!isValid);
    dispatch(va.kyb.business.info.setBusinessStructure(value));
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const isValid = handleValidation(name, value);
    setShowErrors(!isValid);
  };

  const clearAsyncState = useCallback(() => {
    dispatch(va.kyb.sync.apiSuccess(false));
    dispatch(va.kyb.sync.apiFetching(false));
    dispatch(
      va.kyb.sync.apiError({
        status: 0,
        message: "",
      }),
    );
  }, []);
  useEffect(() => {
    clearAsyncState();
    return () => clearAsyncState();
  }, []);

  useEffect(() => {
    if (apiSuccess) {
      clearAsyncState();
      navigate("/kyb/business_address");
    }
  }, [apiSuccess]);

  const handleSubmit = () => {
    const validBusinessName = validateBusinessName(
      info.businessName,
      handleValidationResult("businessName"),
    );
    const validBusinessEin = validateEin(
      info.ein,
      handleValidationResult("businessEin"),
    );
    const validRequiredEin = validateRequiredEin(
      info.ein,
      info.businessStructure,
      handleValidationResult("businessEin"),
    );
    const validBusinessStructure = validateBusinessStructure(
      info.businessStructure,
      handleValidationResult("businessStructure"),
    );

    if (
      !validBusinessName ||
      !validBusinessEin ||
      !validRequiredEin ||
      !validBusinessStructure
    ) {
      setShowErrors(true);
      return;
    }
    if (!apiFetching) {
      dispatch(va.kyb.sync.updateBusiness());
    }
  };

  const handleOnEinKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const value = e.currentTarget.value.replace(/\s+/g, "");
    if (key === "Backspace") {
      let nextValue = value.slice(0, -1);
      if (value.slice(-1) === "-") {
        nextValue = value.slice(0, -2);
      }
      validateEin(nextValue, handleValidationResult("businessEin"));
      dispatch(va.kyb.business.info.setEin(nextValue));
    }
  };

  const handleOnEinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (
      "inputType" in e.nativeEvent &&
      e.nativeEvent.inputType === "deleteContentBackward"
    ) {
      return;
    }
    validateEin(value, handleValidationResult("businessEin"));
    const nextValue = formatEin(value);
    dispatch(va.kyb.business.info.setEin(nextValue));
  };

  return (
    <FadeInStack
      justifyContent="space-between"
      gap={1}
      sx={{
        p: 3,
        maxWidth: "748px",
        width: "100vw",
        flexGrow: 1,
        backgroundColor: "white",
      }}
    >
      <Stack data-testid="header-title" alignItems="flex-start" gap={1}>
        <LinearProgress
          variant="determinate"
          value={12}
          sx={{
            width: "100%",
          }}
        />
        <Typography variant="h2">Business information</Typography>
        <Typography variant="body1">
          Provide the following information as it has been registered.
        </Typography>
      </Stack>
      <Stack
        gap={2}
        data-testid="business-info-input"
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          whiteSpace: "nowrap",
          height: "calc(100vh - 367px)",
          "& .MuiAutocomplete-popper": {
            "::-webkit-scrollbar": {
              width: "12px",
              height: "7px",
            },
            "::-webkit-scrollbar-track": {
              background: "#F1F1F1",
            },
            "::-webkit-scrollbar-thumb": {
              background: "#ddd",
            },
            "::-webkit-scrollbar-thumb:hover": {
              background: "#333",
            },
          },
        }}
      >
        <Stack gap={1}>
          <Typography variant="body1">Business name</Typography>
          <input
            id="businessName-input"
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="Enter registered business name"
            maxLength={64}
            name="businessName"
            value={info.businessName}
            onChange={handleOnBusinessNameChange}
            onBlur={handleOnBlur}
            className="businessName-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.businessName && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.businessName ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.businessName}
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography>Doing business as</Typography>
          <input
            id="doing-business-as-input"
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="Preferred name of the business (optional)"
            maxLength={64}
            name="doingBusinessAs"
            value={info.doingBusinessAs}
            onChange={handleOnDoingBusinessAsChange}
            onBlur={handleOnBlur}
            className="doing-business-as-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.doingBusinessAs && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display:
                showErrors && uiErrors.doingBusinessAs ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.doingBusinessAs}
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography>Business type</Typography>
          <Select
            id="business-structure-select"
            name="businessStructure"
            value={(() => {
              if (!info.businessStructure) return "";
              if (info.businessStructure === BusinessStructureType.NONE)
                return "";
              return info.businessStructure;
            })()}
            onChange={handleOnBusinessStructureChange}
            displayEmpty
            renderValue={(selected) => {
              if (selected == null) {
                return (
                  <Typography color={"gray"}>
                    <em>Select type</em>
                  </Typography>
                );
              }
              switch (selected) {
                case BusinessStructureType.SOLE_PROPRIETORSHIP:
                  return "Sole proprietorship";
                case BusinessStructureType.CORPORATION:
                  return "Corporation";
                case BusinessStructureType.LLC:
                  return "LLC";
                case BusinessStructureType.PARTNERSHIP:
                  return "Partnership";
                default:
                  return (
                    <Typography color={"gray"}>
                      <em>Select type</em>
                    </Typography>
                  );
              }
            }}
          >
            <MenuItem value={BusinessStructureType.SOLE_PROPRIETORSHIP}>
              Sole proprietorship
            </MenuItem>
            <MenuItem value={BusinessStructureType.CORPORATION}>
              Corporation
            </MenuItem>
            <MenuItem value={BusinessStructureType.LLC}>LLC</MenuItem>
            <MenuItem value={BusinessStructureType.PARTNERSHIP}>
              Partnership
            </MenuItem>
          </Select>
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display:
                showErrors && uiErrors.businessStructure ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.businessStructure}
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Typography>EIN</Typography>
          <input
            id="ein-input"
            disabled={apiFetching}
            type="string"
            inputMode="text"
            placeholder="XX-XXXXXXX"
            name="businessEin"
            value={formatEin(info.ein)}
            onChange={handleOnEinChange}
            onBlur={handleOnBlur}
            onKeyDown={handleOnEinKeyDown}
            className="ein-input"
            style={{
              color: theme.palette.primary.main,
              border: `1px solid ${
                uiErrors.businessEin && showErrors
                  ? theme.palette.error.main
                  : theme.palette.secondary.light
              }`,
              backgroundColor: "transparent",
              borderRadius: "4px",
              height: "40px",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "CircularRegular",
              paddingLeft: "10px",
              width: "100%",
            }}
          />
          <Typography
            variant="subtitle1"
            color="error"
            sx={{
              pt: "5px",
              pl: "10px",
              display: showErrors && uiErrors.businessEin ? "block" : "none",
              lineHeight: "16px",
            }}
          >
            {uiErrors.businessEin}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={1} sx={{ height: "100px", backgroundColor: "white" }}>
        <GenericRetryError error={apiError} onClick={handleSubmit} />
        <LocalErrorMsg error={apiError} />
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{ color: theme.palette.secondary.main }}
        >
          We are required by law to collect this information to authorize
          payments.
        </Typography>
        <StitchAsyncButton
          buttonText="Continue"
          variant="contained"
          color="primary"
          logoColor="white"
          onClick={handleSubmit}
          success={apiSuccess}
          loading={apiFetching}
          loadingSize="small"
          loadingPosition={{ top: -31, left: 0 }}
        />
      </Stack>
    </FadeInStack>
  );
}
